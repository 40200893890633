import React, { useState, useEffect } from "react"
// import { useDispatch, useSelector } from "react-redux"


export default function TestProductPage() {
    // const dispatch = useDispatch();
    const [message, setMessage] = useState('no order placed yet!')
    const [cart, setCart] = useState([])

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);

        if (query.get('success')) {
            setMessage("Order Placed!, You will recieve an email confirmation")
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            )
        }

    }, []);



    return (
        <>
            <div className='product-1'>
                <h1>Book</h1>
                <p>This book is about amazing stuff</p>
                <p>Price: $2.00</p>
            </div>
            <div className='product-2'>
                <h1>T-Shirt</h1>
                <p>This shirt is awesome!</p>
                <p>Price: $10</p>
            </div>
            <div className='product-3'>
                <h1>Sunglasses</h1>
                <p>Super dope glasses</p>
                <p>Price: $5</p>
            </div>
            <div className='product-4'>
                <h1>Virtual Item</h1>
                <p>Super rare key</p>
                <p>Price: $1,000</p>
            </div>
            {/* <form action='/create-checkout-session' method='POST'>
                <button type='submit'>Checkout</button>
            </form> */}
        </>
    )
}
