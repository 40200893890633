import React from "react";
import "./AboutPage.css";

import vivHeadshot from "../../images/vivHeadshot.jpeg";

export default function AboutPage() {
  return (
    <div className="about-wrapper">
      <div className="about-first-container">
        <div className='headshot-container'>
          <img src={vivHeadshot} alt="headshot" />
          <div className="about-pic-header">Vivian Geary</div>
        </div>
        <div className="about-first-text-container">
          <div style={{ fontWeight: "600" }}>
            Founded in{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              2015
            </span>
            ,{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              Caring for Family Caregivers
            </span>{" "}
            grew out of{" "}
              love
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
            </span>
            ,{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              passion
            </span>
            ,{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              compassion
            </span>{" "}
            and frustration.
          </div>
          <div style={{ fontWeight: "600" }}>
            The{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              Geary Family
            </span>{" "}
            was faced with a crisis in{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              2006
            </span>{" "}
            when Mom,{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              Eleanor
            </span>
            , suffered a{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              stroke
            </span>{" "}
            that left her affected in many ways. She lost use of her left side,
            her ability to walk and the ability to take care of her own personal
            care. She lost her independence and ability to live on her own.
          </div>
          <div style={{ fontWeight: "600" }}>
            As a family, there were many decisions to make and many unknowns
            about the healthcare system,{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              Medicare
            </span>
            , medical treatments, nursing homes, rehab facilities, home
            modifications, equipment to help with mobility and independence and
            many other areas.
          </div>
          <div style={{ fontWeight: "600" }}>
            It took about{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              18 months
            </span>{" "}
            to get a{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              "new normal"
            </span>{" "}
            established and another year to get comfortable with all of the
            elements of a new routine. During that time of adjustment, it did
            not seem that we knew any other families going through the same
            issues. As the primary caregiver, I had very few places to turn to
            for answers. I started from scratch with almost every issue that
            arose. It was a very{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              frustrating
            </span>{" "}
            time.
          </div>
          <div style={{ fontWeight: "600" }}>
            I was unsure that I was making the right decisions and did not have
            a lot of information from which to base my decisions. I did a lot of{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              research
            </span>{" "}
            and I created my own{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              support system
            </span>
            . It was hard work!! Over the past decade, my{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              knowledge base
            </span>{" "}
            has grown.
          </div>
          <div style={{ fontWeight: "600" }}>
            And more people close to us are taking care of their loved ones. I
            have been able to share information and a{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              sympathetic ear
            </span>{" "}
            with families just beginning their journeys into{" "}
            <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
              caregiving
            </span>{" "}
            and those who have been caregiving for many years.
          </div>
        </div>
      </div>
      <div className="about-second-container">
        <div style={{ fontWeight: "600" }}>
          I believe that no one should have to go through being a family
          caregiver all alone. The job is isolating enough simply because the
          care happens in the{" "}
          <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
            privacy
          </span>{" "}
          of your own home. But there should be someone you can turn to if you
          have{" "}
          <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
            questions
          </span>
          , if you need a{" "}
          <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
            sounding board
          </span>{" "}
          to help you make a tough decision or if you need to{" "}
          <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>
            vent
          </span>{" "}
          to someone who understands where you are coming from. <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>Caregiving</span> does
          not have to be so hard! That is what <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>Caring for Family Caregivers</span> is
          all about. Making you <span className='about-span' style={{ color: "rgb(50, 94, 117)"}}>stronger</span> so that you can help your family
          better.
        </div>
      </div>
    </div>
  );
}
