import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useModal } from "../../context/Modal";
import "./CongratsModal.css";

// Mailchimp
import { subscribeToMailchimp } from "../../store/mailchimp";

// Icons
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export default function CongratsModal() {
  const [currentPage, setCurrentPage] = useState("congrats");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [success, setSuccess] = useState(false);

  const { closeModal } = useModal();
  const dispatch = useDispatch();

  const mailchimpState = useSelector((state) => state.mailchimp);

  // Mailchimp Success setState
  useEffect(() => {
    if (mailchimpState.subscribed) {
      setSuccess(true);
    }
  }, [mailchimpState.subscribed]);

  // Success Modal Message
  useEffect(() => {
    let timeoutId;
    if (success) {
      timeoutId = setTimeout(() => {
        // Reset the state and close the modal
        setSuccess(false);
        // history.push('/')
        closeModal();
      }, 1250);
    }
    return () => clearTimeout(timeoutId);
  }, [success]);

  // Subscribe dispatch call
  const handleSubscribe = () => {
    // Dispatch the subscribe action
    dispatch(
      subscribeToMailchimp({
        email,
        first_name: firstName,
        last_name: lastName,
      })
    );
  };

  return (
    <div className="congrats-modal-wrapper">
      <div className="close-congrats-modal">
        {/* <div onClick={closeModal}>x</div> */}
        <FontAwesomeIcon
          onClick={closeModal}
          id="close-modal-button"
          icon={faCircleXmark}
        />
      </div>
      {currentPage === "congrats" && (
        <div className="congrats-page-one">
          <div>
            {success === true ? (
              <FontAwesomeIcon
                style={{ fontSize: "125px", color: "green" }}
                beat
                icon={faCheckCircle}
              />
            ) : (
              <FontAwesomeIcon
                style={{ fontSize: "125px" }}
                beat
                icon={faEnvelope}
              />
            )}
          </div>
          {success === true ? (
            <>
              <div style={{ fontSize: "35px" }}>Thank you for subscribing!</div>
              {/* <div>You are now part of Tuesday Tips for Caregivers.</div> */}
            </>
          ) : (
            <>
              <div>Thank you for being here!</div>
              <div>
                Enjoy 10 <span style={{ color: "green" }}>FREE</span> Caregiver
                Tips
              </div>
              <div>
                by signing up for our{" "}
                <span style={{ color: "#d48933" }}>Tuesday Tips</span>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubscribe();
                }}
                className="congrats-form"
              >
                <div>
                  <input
                    placeholder="First name"
                    onChange={(e) => setFirstName(e.target.value)}
                    style={{ width: "50%" }}
                  />
                  <input
                    placeholder="Last name"
                    onChange={(e) => setLastName(e.target.value)}
                    style={{ width: "50%" }}
                  />
                </div>
                <input
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="congrats-terms-agreement">
                  By subscribing to Tuesday Tips for Caregivers, you agree to
                  receive weekly emails containing caregiving tips and
                  information. We respect your privacy and will not share your
                  information with third parties.
                </div>
                <button type="submit">SUBSCRIBE</button>
              </form>
            </>
          )}
        </div>
      )}

      {currentPage === "subscribe-form" && (
        <div className="congrats-page-two">
          <FontAwesomeIcon icon={faEnvelope} fontSize="150px" />
          <div>Subscribe to our Newsletter</div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubscribe();
            }}
          >
            <input
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      )}
    </div>
  );
}
