import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Navigation.css";
import white from "../../images/white.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";


import { logout } from "../../store/session";
import { getUserCart } from "../../store/cart";
import { getAllProducts } from "../../store/product";

function Navigation({ isLoaded }) {
  const sessionUser = useSelector((state) => state.session.user);
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();
  const divRef = useRef();
  const cart = useSelector((state) => state.cart.items);
  const products = useSelector((state) => state.products.allProducts);
  const [total, setTotal] = useState("");

  const openMenu = () => {
    if (showMenu) return;
    setShowMenu(true);
  };

  const closeMenu = () => setShowMenu(false);
  const history = useHistory();

  const goHome = () => {
    history.push("/");
  };

  const createLineItems = (cart, products) => {
    return cart
      .map((cartItem) => {
        const product = products.find(
          (product) => product.product_id === cartItem.product_id
        );

        if (!product) {
          return null; // Handle the case where the product is not found
        }

        return {
          price: product.stripe_price_id, // Replace with the actual field for your Stripe price ID
          quantity: cartItem.quantity,
        };
      })
      .filter((item) => item !== null); // Remove any null items
  };

  useEffect(() => {
    if (Array.isArray(cart) && cart.length > 0) {
      // Calculate the total based on all products in the cart
      const total = cart.reduce((acc, cartItem) => {
        const product = products.find(
          (product) => product.product_id === cartItem.product_id
        );

        if (product) {
          return acc + product.price * cartItem.quantity;
        }

        return acc;
      }, 0);

      setTotal(total);
    }
  }, [cart, products]);

  useEffect(() => {
    // Dispatch the action to get all products when the component is first rendered
    dispatch(getAllProducts());
  }, [dispatch]);

  useEffect(() => {
    if (showMenu && sessionUser) {
      // Dispatch the action to get user's cart
      dispatch(getUserCart());
    }
  }, [showMenu, sessionUser, dispatch]);

  useEffect(() => {
    const closeMenu = (e) => {
      if (divRef.current && !divRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    const handleDocumentClick = (e) => {
      closeMenu(e);
    };

    // Attach the click event listener when the dropdown is shown
    if (showMenu) {
      document.addEventListener("click", handleDocumentClick);
    }

    // Remove the event listener when the component is unmounted or when the dropdown is hidden
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [showMenu]);

  const handleLogout = async (e) => {
    e.preventDefault();
    await dispatch(logout());
  };

  // const handleCheckout = async () => {
  //   const lineItems = createLineItems(cart, products);
  //   await dispatch(createCheckoutSession(lineItems));
  //   // Any code that should execute after the checkout session creation is complete
  // };

  const divClassName = "profile-dropdown" + (showMenu ? "" : " hidden");

  const isIPhone12 = window.matchMedia("(max-width: 428px)").matches;

  return (
    <div className="nav-wrapper">
      <div className="logo-box">
        <img src={white} id="nav-main-logo" alt="logo" onClick={goHome}></img>
      </div>
      <div className="tab-container">
        <div className="top-divider">
          {/* ... (unchanged code) */}
        </div>
        <div className="bottom-divider">
          {isIPhone12 ? (
            <>
              <NavLink exact to="/" style={{ fontSize: '30px'}} activeClassName="active-link">
                <FontAwesomeIcon icon={faHome} />
              </NavLink>
              <NavLink exact to="/about" style={{ fontSize: '30px'}} activeClassName="active-link">
                <FontAwesomeIcon icon={faUser} />
              </NavLink>
              <NavLink exact to="/events" style={{ fontSize: '30px'}} activeClassName="active-link">
                <FontAwesomeIcon icon={faCalendar} />
              </NavLink>
              <NavLink exact to="/shop" style={{ fontSize: '30px'}} activeClassName="active-link">
                <FontAwesomeIcon icon={faShoppingCart} />
              </NavLink>
              <NavLink exact to="/contact" style={{ fontSize: '30px'}} activeClassName="active-link">
                <FontAwesomeIcon icon={faPaperPlane} />
              </NavLink>
            </>
          ) : (
            <>
              <NavLink exact to="/" activeClassName="active-link">
                Home
              </NavLink>
              <NavLink exact to="/about" activeClassName="active-link">
                About
              </NavLink>
              <NavLink exact to="/events" activeClassName="active-link">
                Events
              </NavLink>
              <NavLink exact to="/shop" activeClassName="active-link">
                Shop
              </NavLink>
              <NavLink exact to="/contact" activeClassName="active-link">
                Contact
              </NavLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
