import React, { useEffect } from 'react';
import { useModal } from '../../context/Modal';

function AutoOpenModal({
  modalComponent, // component to render inside the modal
  delay, // optional: delay in milliseconds before opening the modal
  onModalClose // optional: callback function that will be called once the modal is closed
}) {
  const { setModalContent, setOnModalClose } = useModal();

  useEffect(() => {
    // Open the modal after the specified delay (default is 3000 milliseconds)
    const timerId = setTimeout(() => {
      if (onModalClose) setOnModalClose(onModalClose);
      setModalContent(modalComponent);
    }, delay || 5000);

    // Clean up the timer to avoid memory leaks
    return () => clearTimeout(timerId);
  }, [delay, onModalClose, setModalContent, setOnModalClose]);

  return null; // This component doesn't render anything directly
}

export default AutoOpenModal;
