import React from "react";
import "./Footer.css";
import { useHistory } from "react-router-dom";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";
import { subscribeToMailchimp } from "../../store/mailchimp";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function Footer() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailPlaceholder, setEmailPlaceholder] = useState("Subscribe with Email");
  const [isCheckMarkVisible, setIsCheckMarkVisible] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  const mailchimpState = useSelector((state) => state.mailchimp);
  const { subscribed, error } = useSelector((state) => state.mailchimp)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mailchimpState.subscribed) {
      setSuccess(true);
      setLoading(false)
      setEmail("")
      setIsCheckMarkVisible(true);

      // Show "Thank you for subscribing" message
      setShowThankYouMessage(true);

    }
  }, [mailchimpState.subscribed]);

  useEffect(() => {
    let timeoutId;
    if (success) {
      timeoutId = setTimeout(() => {
        // Reset the state and close the modal
        setSuccess(false);
        setIsCheckMarkVisible(false);
        setShowThankYouMessage(false);
        setEmailPlaceholder("Subscribe with Email")
      }, 2500);
    }
    return () => clearTimeout(timeoutId);
  }, [success]);

  const goToContact = () => {
    // Navigate to the '/contact' route
    history.push("/contact");

    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can remove this if you prefer an instant scroll
    });
  };

  const goToFacebook = () => {
    // Open the Facebook link in a new tab
    window.open("https://www.facebook.com/CaringforFamilyCaregivers", "_blank");
  };

  const goToInstagram = () => {
    // Open the Instagram link in a new tab
    window.open(
      "https://www.instagram.com/caring_for_family_caregivers_/",
      "_blank"
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      // Optionally show an error message or handle the empty input case
      return;
    }

    try {
      setLoading(true);

      // Dispatch the subscribe action
      await dispatch(
        subscribeToMailchimp({
          email,
          first_name: 'Eli',
          last_name: 'Foster'
        })
      );

      // Reset loading to false if the request is successful
      setLoading(false);
    } catch (error) {
      console.error("Error subscribing to Mailchimp:", error);
      // Reset loading to false in case of an error
      setLoading(false);
    }
  };


  const handleEmailFocus = () => {
    // Clear the placeholder when the input is focused
    setEmailPlaceholder("");
    setShowThankYouMessage(false);
  };

  const handleEmailBlur = () => {
    // Restore the placeholder if the input is left empty
    if (email === "") {
      setEmailPlaceholder("Subscribe with Email");
    }
  };

  return (
    <div className="footer-wrapper">
      <img
        src="https://web.archive.org/web/20221108014123im_/http://familycaregiversnow.com/wp-content/uploads/2015/10/logo-footer.png"
        id="footer-logo"
        alt="alt-logo"
      ></img>
      <div className="footer-nl-container">
        <div className="wavy">Don't Forget our Tuesday Tips News Letter!</div>
        <form onSubmit={handleFormSubmit}>
          <div className='footer-input-container'>
            <input
              type="email"
              id='footer-input'
              value={email}
              placeholder={
                showThankYouMessage
                  ? "Thank you for subscribing!"
                  : emailPlaceholder
              }
              onChange={(e) => setEmail(e.target.value)}
              onFocus={handleEmailFocus}
              onBlur={handleEmailBlur}
              disabled={showThankYouMessage}
            />
            <button type="submit">
            { loading ? (
              <FontAwesomeIcon icon={faSpinner} spinPulse />
            ) : isCheckMarkVisible ? (
                    <FontAwesomeIcon icon={faCircleCheck} id='footer-success-icon' />
                  ) : (
                    <FontAwesomeIcon icon={faPaperPlane} />
                  )}
            </button>
          </div>
          {error && <ul id='footer-input-error'>
            <li>{error}</li>
            </ul>}
        </form>
      </div>
      <div className="footer-contact-container">
        <div>Check us out at</div>
        <div onClick={goToFacebook}>
          <FontAwesomeIcon
            style={{ fontSize: "35px" }}
            icon={faSquareFacebook}
          />
          <div>Facebook</div>
        </div>
        <div onClick={goToInstagram}>
          <FontAwesomeIcon style={{ fontSize: "35px" }} icon={faInstagram} />
          <div>Instagram</div>
        </div>
        <div onClick={goToContact}>
          <FontAwesomeIcon style={{ fontSize: "33px" }} icon={faAddressBook} />
          <div>Contact Us</div>
        </div>
      </div>
    </div>
  );
}
