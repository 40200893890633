import React from "react";
import "./EventsPage.css";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePhone } from "@fortawesome/free-solid-svg-icons";

export default function EventsPage() {
  const joinConference = () => {
    // Open the link in a new tab
    window.open("https://hello.freeconference.com/conf/call/4362560", "_blank");
  };

  return (
    <div className="event-wrapper">
      <div className="event-title-container">
        <div>Caregiver Support Group</div>
      </div>
      {/* <div className="event-footer-container">
          <div className='pan-text'>Let's Talk! - What is your Holiday Strategy?</div>
      </div> */}
      <div className="event-about-container">
        <div className="ea-box-one">
          <div>Join The Family</div>
          <div>
            As a family caregiver, you have a lot of challenges. And with the
            help of a caring community, there’s nothing you can’t handle! If you
            care for your child, spouse, sibling, parent or extended family
            members at home, this group is for you. Share your story or listen
            to others. And gain some new insights.
          </div>
        </div>
        <div className="ea-box-two">
          <div>We Grow Together!</div>
          <div>Saturday, August 17th, 2024</div>
          <div>9:00am - 11:00am EST</div>
        </div>
      </div>
      <div className="event-contact-container">
        <div className="ec-box-one">
          <div>
            <div>Call-in participation</div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faSquarePhone}
              style={{ fontSize: "36px", color: "#32a31c" }}
            />
            <div>(605)-475-4120</div>
          </div>
          <div>
            <div>Participant Code: 436 2560</div>
          </div>
        </div>
        <div className="ec-box-two">
          <div>OR</div>
        </div>
        <div className="ec-box-three">
          <div>
            <div>Connect with FreeConference</div>
          </div>
          <div>
            <button id="join-conference-button" onClick={joinConference}>
              JOIN
            </button>
          </div>
        </div>
      </div>
      {/* <div className="event-footer-container">
          <div className='pan-text'>Let's Talk! - What is your Holiday Strategy?</div>
      </div> */}
    </div>
  );
}
