import React from "react";

import './Header.css'

export default function Header() {
  return (
    <div className="header-wrapper">
      <a
        href="https://www.surveymonkey.com/r/GXSMWDZ"
        target="_blank"
        rel="noopener noreferrer"
        className="header-pan-text"
      >
        Take our Family Caregiver Survey 🌼
      </a>
    </div>
  );
}
