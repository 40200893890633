// product.js

const GET_ALL_PRODUCTS = "products/GET_ALL_PRODUCTS";

// Action creators
const getAllProductsAction = (products) => ({
  type: GET_ALL_PRODUCTS,
  payload: products,
});

// Thunk
export const getAllProducts = () => async (dispatch) => {
  try {
    const response = await fetch("/api/products/get_all_products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(getAllProductsAction(data.products));
      return null;
    } else {
      const data = await response.json();
      return data.error || ["An error occurred. Please try again."];
    }
  } catch (error) {
    console.error("Error fetching all products:", error);
    return ["An error occurred. Please try again."];
  }
};

// Reducer
const initialState = { allProducts: [] };

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return { ...state, allProducts: action.payload };
    // Add more cases if needed for other actions
    default:
      return state;
  }
};

export default products;
