import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router-dom";
import SignupFormPage from "./components/SignupFormPage";
import LoginFormPage from "./components/LoginFormPage";
import { authenticate } from "./store/session";
import Navigation from "./components/Navigation";
import TestProductPage from "./components/TestProductPage";
import HomePage from "./components/HomePage";
import AboutPage from "./components/AboutPage";
import EventsPage from "./components/EventsPage";
import ShopPage from "./components/ShopPage";
import Footer from "./components/Footer";

import AutoOpenModal from "./components/AutoOpenModal";
import CongratsModal from "./components/CongratsModal";
import ContactPage from "./components/ContactPage";

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    dispatch(authenticate()).then(() => setIsLoaded(true));
  }, [dispatch]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
    <div className='app-base'>
      <Navigation isLoaded={isLoaded} />
      {isLoaded && (
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route exact path="/login" >
            <LoginFormPage />
          </Route>
          <Route exact path="/signup">
            <SignupFormPage />
          </Route>
          <Route exact path='/testproduct'>
            <TestProductPage />
          </Route>
          <Route exact path='/shop'>
            <ShopPage />
          </Route>
          <Route exact path='/contact'>
            <ContactPage />
          </Route>
          <Route exact path='/about'>
            <AboutPage />
          </Route>
          <Route exact path='/events'>
            <EventsPage />
          </Route>
        </Switch>
      )}
    </div>
        <Footer />
    </div>
  );
}

export default App;
