// // mailchimp.js

// // Action types
// const SUBSCRIBE_SUCCESS = "mailchimp/SUBSCRIBE_SUCCESS";
// const SUBSCRIBE_FAILURE = "mailchimp/SUBSCRIBE_FAILURE";

// // Action creators
// const subscribeSuccessAction = () => ({
//   type: SUBSCRIBE_SUCCESS,
// });

// const subscribeFailureAction = () => ({
//   type: SUBSCRIBE_FAILURE,
// });

// // Thunk for subscribing to Mailchimp
// export const subscribeToMailchimp = ({ email, first_name, last_name }) => async (dispatch) => {
//   try {
//     const response = await fetch("/subscribe", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ email, first_name, last_name }),
//     });

//     const data = await response.json(); // Parse the response body as JSON

//     if (response.ok && data.success) {
//       dispatch(subscribeSuccessAction());
//     } else {
//       dispatch(subscribeFailureAction());
//     }
//   } catch (error) {
//     console.error("Error subscribing to Mailchimp:", error);
//     dispatch(subscribeFailureAction());
//   }
// };

// // Reducer
// const initialState = {
//   subscribed: false,
// };

// const mailchimpReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case SUBSCRIBE_SUCCESS:
//       return { ...state, subscribed: true };
//     case SUBSCRIBE_FAILURE:
//       return { ...state, subscribed: false };
//     default:
//       return state;
//   }
// };

// export default mailchimpReducer;




// mailchimp.js

// Action types
const SUBSCRIBE_SUCCESS = "mailchimp/SUBSCRIBE_SUCCESS";
const SUBSCRIBE_FAILURE = "mailchimp/SUBSCRIBE_FAILURE";

// Action creators
const subscribeSuccessAction = () => ({
  type: SUBSCRIBE_SUCCESS,
});

const subscribeFailureAction = (error) => ({
  type: SUBSCRIBE_FAILURE,
  error,
});

// Thunk for subscribing to Mailchimp
export const subscribeToMailchimp = ({ email, first_name, last_name }) => async (dispatch) => {
  try {
    const response = await fetch("/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, first_name, last_name }),
    });

    const data = await response.json(); // Parse the response body as JSON

    if (response.ok && data.success) {
      dispatch(subscribeSuccessAction());
    } else {
      // If there's an error, dispatch the failure action with the error message
      dispatch(subscribeFailureAction(data.error_message || "Subscription failed"));
    }
  } catch (error) {
    console.error("Error subscribing to Mailchimp:", error);
    dispatch(subscribeFailureAction("Subscription failed"));
  }
};

// Reducer
const initialState = {
  subscribed: false,
  error: null, // Add error field to the initial state
};

const mailchimpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIBE_SUCCESS:
      return { ...state, subscribed: true, error: null }; // Clear error on success
    case SUBSCRIBE_FAILURE:
      return { ...state, subscribed: false, error: action.error };
    default:
      return state;
  }
};

export default mailchimpReducer;
