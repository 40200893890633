// stripe.js
// Action types
const CREATE_CHECKOUT_SESSION_SUCCESS = 'stripe/CREATE_CHECKOUT_SESSION_SUCCESS';
const CREATE_CHECKOUT_SESSION_FAILURE = 'stripe/CREATE_CHECKOUT_SESSION_FAILURE';

// Action creators
export const createCheckoutSessionSuccess = (sessionId) => ({
  type: CREATE_CHECKOUT_SESSION_SUCCESS,
  payload: { sessionId },
});

export const createCheckoutSessionFailure = (error) => ({
  type: CREATE_CHECKOUT_SESSION_FAILURE,
  payload: { error },
});

// Thunk for creating a checkout session
export const createCheckoutSession = (lineItems) => async (dispatch) => {
    console.log('thunk', lineItems)
  try {
    const response = await fetch('/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ lineItems }),
    });

    if (response.ok) {
      const { sessionId } = await response.json();
      dispatch(createCheckoutSessionSuccess(sessionId));
    } else {
      dispatch(createCheckoutSessionFailure('Failed to create checkout session'));
    }
  } catch (error) {
    console.error('Error creating checkout session:', error);
    dispatch(createCheckoutSessionFailure('Error creating checkout session'));
  }
};

// Initial state
const initialState = {
  sessionId: null,
  error: null,
};

// Reducer
const stripe = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        sessionId: action.payload.sessionId,
        error: null,
      };
    case CREATE_CHECKOUT_SESSION_FAILURE:
      return {
        ...state,
        sessionId: null,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default stripe;
