import React from "react";
import "./ContactPage.css";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";


export default function ContactPage() {
  return (
    <div className="contact-wrapper">
      <div className="contact-container-one">
        <div>Contact Us!</div>
        <p>
         Leave your information and send us a message, we will get back you to you.
        </p>
        <form>
          <input type="text" placeholder="Your name" />
          <input type="text" placeholder="Email" />
          <textarea type="text" placeholder="Message" rows="10" cols="20" />
          <button>Send</button>
        </form>
      </div>
      <div className="contact-container-two">
        <div className="contact-content-container">
          <div>
            <FontAwesomeIcon style={{ fontSize: "45px", color: "rgb(50, 94, 117)" }} icon={faHouse} />
            <div>Orlando, Florida</div>
          </div>
          <div>
            <FontAwesomeIcon
              style={{ fontSize: "50px", color: "rgb(50, 94, 117)" }}
              icon={faSquarePhone}
            />
            <div>(407)-504-0868</div>
          </div>
          <div>
            <FontAwesomeIcon style={{ fontSize: "50px", color: "rgb(50, 94, 117)" }} icon={faEnvelope} />
            <div>caringforfamilycaregivers@gmail.com</div>
          </div>
        </div>
        <div className="social-icons-container">
        </div>
      </div>
    </div>
  );
}
