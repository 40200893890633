const CREATE_CART = "cart/CREATE_CART";
const UPDATE_CART = "cart/UPDATE_CART"; // If you want to handle updates to the cart
const REMOVE_CART = "cart/REMOVE_CART";

const GET_USER_CART = "cart/GET_USER_CART";
const ADD_TO_CART = "cart/ADD_TO_CART";

// Action creators
const createCartAction = (cart) => ({
  type: CREATE_CART,
  payload: cart,
});

const updateCartAction = (cart) => ({
  type: UPDATE_CART,
  payload: cart,
});

const removeCartAction = () => ({
  type: REMOVE_CART,
});

const addToCartAction = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

const getUserCartAction = (cart) => ({
  type: GET_USER_CART,
  payload: cart,
});

// Thunks
export const getUserCart = () => async (dispatch) => {
  try {
    const response = await fetch("/api/cart/get_user_cart", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(getUserCartAction(data.cart));
      return null;
    } else {
      const data = await response.json();
      return data.error || ["An error occurred. Please try again."];
    }
  } catch (error) {
    console.error("Error fetching user cart:", error);
    return ["An error occurred. Please try again."];
  }
};

export const createCart = () => async (dispatch) => {
  try {
    const response = await fetch("/api/cart/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(createCartAction(data));
      return null;
    } else if (response.status < 500) {
      const data = await response.json();
      if (data.errors) {
        return data.errors;
      }
    } else {
      return ["An error occurred. Please try again."];
    }
  } catch (error) {
    console.error("Error creating cart:", error);
    return ["An error occurred. Please try again."];
  }
};

export const addToCart = (product_id, quantity) => async (dispatch) => {
  try {
    const response = await fetch("/api/cart/add_to_cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        product_id,
        quantity,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      dispatch(addToCartAction(data));
      return null;
    } else if (response.status < 500) {
      const data = await response.json();
      if (data.errors) {
        return data.errors;
      }
    } else {
      return ["An error occurred. Please try again."];
    }
  } catch (error) {
    console.error("Error adding to cart:", error);
    return ["An error occurred. Please try again."];
  }
};

// Reducer
const initialState = { items: [] };

const cart = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CART:
      return { ...state, items: action.payload };
    case GET_USER_CART:
      return { ...state, items: action.payload };
    case ADD_TO_CART:
      return { ...state, items: action.payload };
    case UPDATE_CART:
      // Handle cart updates if needed
      return state;
    case REMOVE_CART:
      return { ...state, items: null };
    default:
      return state;
  }
};

export default cart;
