import React from "react";
import "./ShopPage.css";
import bookCover from "../../images/bookCover.jpeg";

import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cart";

import { useState } from "react";

import vivianVideo from "../../images/vivianVideo.mp4";

export default function ShopPage() {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleResponseMessages = (response) => {
    if (response.message) {
      // If there is a success message in the response, use it
      setSuccessMessage(response.message);
    } else if (response.error) {
      // If there is an error message in the response, use it
      setErrorMessage(response.error);
    } else {
      // If there are no specific messages, you can handle it as needed
      // For example, you might want to display a default success message
      setSuccessMessage("Operation successful");
    }

    // Optionally, clear the messages after a certain time
    setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 5000);
  };

  const handleAddToCart = async () => {
    const productId = "1"; // Replace with the actual product ID
    const quantity = 1; // Replace with the actual quantity

    dispatch(addToCart(productId, quantity));
  };

  return (
    <div className="shop-wrapper">
        <video width="100%" autoPlay loop muted>
          <source src={vivianVideo} />
        </video>
      <div className="book-shop-wrapper">
        <div className="bs-container-one">
          <img src={bookCover} id="shop-book-cover" alt="book-cover" />
        </div>
        <div className="bs-container-two">
          <div>Mommy Philosophies</div>
          <div className="book-text-container">
            <div>
              You know how the things your mom repeated the most are the things
              that you still hear ringing inside your head? I know that feeling
              oh too well because I was my mother's primary caregiver for the
              last 10 years of her life. I can still hear her saying things
              like, "when given the choice of 'either/or', usually the best
              answer is BOTH!" Or "all is well", which she said at least ten
              times each day, even on days when it really did not appear to be.{" "}
            </div>
            <div>
              Now that she is no longer physically with us, I figured I would
              explain the meaning behind her most popular sayings to keep her
              spirit and her memory alive in all those that learned her story.
              This is Mommy Philosophies: Lessons I Learned from my Mom During
              Caregiving.
            </div>
            <div>
              As the middle child and only girl in her family, Vivian Geary has
              been a long-time observer of humans. She watched and learned her
              place in her family and her community, becoming astute at seeing
              the needs of others, empathizing, and moving to help fill those
              needs. She created Caring for Family Caregivers as a resource to
              others, providing caregiver coaching and support groups for family
              caregivers. As a speaker and trainer, she conducts seminars and
              classes on a variety of topics pertinent to all aspects of the
              caregiving journey.
            </div>
          </div>
          <form action="/create-checkout-session" method="POST">
            <button type="submit">BUY NOW</button>
          </form>
        </div>
      </div>
    </div>
  );
}
