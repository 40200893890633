import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ModalProvider, Modal, useModal } from "./context/Modal";
import configureStore from "./store";
import * as sessionActions from "./store/session";
import App from "./App";

import "./index.css";

import Header from "./components/Header";
import CongratsModal from "./components/CongratsModal";
import AutoOpenModal from "./components/AutoOpenModal";

const store = configureStore();

if (process.env.NODE_ENV !== "production") {
  window.store = store;
  window.sessionActions = sessionActions;
}

// Wrap the application with the Modal provider and render the Modal component
// after the App component so that all the Modal content will be layered as
// HTML elements on top of all the other HTML elements:
function Root() {
  return (
    <ModalProvider>
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <Header />
          </div>
          <App />
          <Modal />
          {/* Add AutoOpenModal to open CongratsModal after a delay */}
          <AutoOpenModal modalComponent={<CongratsModal />} delay={2000} />
        </BrowserRouter>
      </Provider>
    </ModalProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);


// import React from "react";
// import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
// import { BrowserRouter } from "react-router-dom";

// import { ModalProvider, Modal } from "./context/Modal";
// import configureStore from "./store";
// import * as sessionActions from "./store/session";
// import App from "./App";

// import "./index.css";

// const store = configureStore();

// if (process.env.NODE_ENV !== "production") {
// 	window.store = store;
// 	window.sessionActions = sessionActions;
// }

// // Wrap the application with the Modal provider and render the Modal component
// // after the App component so that all the Modal content will be layered as
// // HTML elements on top of the all the other HTML elements:
// function Root() {
// 	return (
// 		<ModalProvider>
// 			<Provider store={store}>
// 				<BrowserRouter>
// 					<App />
// 					<Modal />
// 				</BrowserRouter>
// 			</Provider>
// 		</ModalProvider>
// 	);
// }

// ReactDOM.render(
// 	<React.StrictMode>
// 		<Root />
// 	</React.StrictMode>,
// 	document.getElementById("root")
// );
