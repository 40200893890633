import React from "react";
import "./HomePage.css";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LinkPreview from "../LinkPreview";
// Content
import vivianVideo from "../../images/vivianVideo.mp4";
import treeLogo from "../../images/treeLogo.png";

import pushing from "../../images/pushing.jpeg";
import cup from "../../images/cup.jpeg";
import meditating from "../../images/meditating.jpeg";
import hands from "../../images/hands.jpeg";
import womenWalking from "../../images/womenWalking.jpeg";
import whiteCouple from "../../images/whiteCouple.jpeg";

import linkOne from "../../images/linkOne.webp";

export default function HomePage() {
  const linkPreviewURLs = [
    "https://www.seniorresourcealliance.org/",
    "https://www.seniorsfirstinc.org/",
    "http://www.orlandohealth.com/physician-practices/orlando-health-multiple-sclerosis-comprehensive-care-center",
    "http://www.ucpcfl.org/",
    "http://www.floridaautismcenter.info/",
    "https://www.autismspeaks.org/resource/central-florida-autism-institute",
    "http://www.dsacf.org/",
    "http://www.wgcancer.org/",
    "http://www.isablefoundation.org/",
    "http://supportforspecialneeds.com/",
    "http://www.caregiveraction.org/",
    "http://www.pbs.org/wgbh/caringforyourparents/caregiver/gettingstarted/index.html",
  ];

  const divStyle = {
    backgroundImage: `url(${linkOne})`,
    backgroundSize: "cover",
    height: "500px",
    // Add additional background properties or styles if needed
  };

  return (
    <div className="home-wrapper">
      {/* <video width="100%" autoPlay loop muted>
        <source src={vivianVideo} />
      </video> */}
      <Carousel>
        <Carousel.Item interval={5000}>
          <img
            src={whiteCouple}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
            alt="slide image"
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">The toughest job you will ever love</h3>
            {/* <p id='subtext'>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            src={pushing}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
            alt="slide image"
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">You are doing a great job!</h3>
            {/* <p id='subtext'>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            src={cup}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
            alt="slide image"
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">You can't pour from an empty cup</h3>
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            src={meditating}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
            alt="slide image"
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">Self-care is not selfish</h3>
            {/* <p id='subtext'>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            src={hands}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">You are equipped for this</h3>
            {/* <p id='subtext'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <img
            src={womenWalking}
            style={{ height: "900px", width: "100%", objectFit: "cover" }}
          />
          <Carousel.Caption style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', width: '80%' }}>
            <h3 id="carousel-title">You have got this!</h3>
            {/* <p id='subtext'>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="home-first-container">
        <div className="mission-box">
          <h1>Our Mission</h1>
          <div>
            To provide opportunities for family caregivers to gain much-needed
            knowledge to make them more effective in their role.
          </div>
          <div>
            To provide an environment that promotes healthy communication and an
            opportunity for problem solving with members of the family
            caregiving community.
          </div>
          <div>
            To offer activities that allow family caregivers to regularly engage
            in self-care so that they do not lose themselves in the process of
            caring for their loved one.
          </div>
        </div>
        <img src={treeLogo} id="tree-logo" alt="tree-logo" />
      </div>
      <div className="resources-wrapper">
        <div id="resource-title-container">
          <h1>Resource Center</h1>
          <div id="resource-sub-text">
  Welcome to our Resource Center, a comprehensive hub offering a wealth of information and support for various aspects of caregiving and well-being. Explore the curated categories below to access valuable resources tailored to specific needs.
  Click on the links to learn more and empower yourself with valuable insights and assistance.
</div>
        </div>
        <div className="resources-tabs-container">
          <div>
            <div>
              <div id="category-title">Seniors</div>
              <div id="line"></div>
            </div>
            <a href="https://www.seniorresourcealliance.org/" target="_blank">
              Senior Resource Alliance
            </a>
            <a href="https://www.seniorsfirstinc.org/" target="_blank">
              Seniors First
            </a>
            <a href="https://helpforcaregivers.org/" target="_blank">
              Share The Care
            </a>
          </div>
          <div>
            <div>
              <div id="category-title">Condition-Specific</div>
              <div id="line"></div>
            </div>
            <a href="http://adrccares.org/" target="_blank">
              Alzheimers and Dementia Resource Center
            </a>
            <a
              href="http://www.orlandohealth.com/physician-practices/orlando-health-multiple-sclerosis-comprehensive-care-center"
              target="_blank"
            >
              Orlando Health MS Comprehensive Care Center
            </a>
            <a href="http://www.ucpcfl.org/" target="_blank">
              UCP of Central Florida
            </a>
            <a href="http://www.floridaautismcenter.info/" target="_blank">
              The Florida Autism Center
            </a>
            <a
              href="https://www.autismspeaks.org/resource/central-florida-autism-institute"
              target="_blank"
            >
              Central Florida Autism Institute
            </a>
            <a href="http://www.dsacf.org/" target="_blank">
              Down Syndrome of Central Florida
            </a>
            <a href="http://www.wgcancer.org/" target="_blank">
              Women &amp; Girls Cancer Alliance
            </a>
          </div>
          <div>
            <div>
              <div id="category-title">Caregivers</div>
              <div id="line"></div>
            </div>
            <a href="http://www.caregiveraction.org/" target="_blank">
              Caregiver Action Network
            </a>
            <a
              href="http://www.pbs.org/wgbh/caringforyourparents/caregiver/gettingstarted/index.html"
              target="_blank"
            >
              PBS Caring For My Parent Handbook
            </a>
          </div>
          <div>
            <div>
              <div id="category-title">Special Needs</div>
              <div id="line"></div>
            </div>
            <a href="http://supportforspecialneeds.com/" target="_blank">
              Support for Special Needs
            </a>
            <a href="" target="_blank"></a>
            <a href="" target="_blank"></a>
          </div>
        </div>
      </div>
    </div>
  );
}
